module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"KUNANA CO.,LTD","short_name":"KUNANA","lang":"en","icon":"src/images/icon.png","background_color":"#fff","start_url":"/","display":"minimal-ui","theme_color_in_head":false,"crossOrigin":"anonymous","icon_options":{"purpose":"any maskable"},"legacy":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"458aafe607aec480b4e520a9df7bf67f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","th"],"defaultLanguage":"en","i18nextOptions":{"interpolation":{"escapeValue":false},"fallbackLng":"en","lowerCaseLng":true,"debug":false}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
